import React from 'react';
import ipad from '../assets/images/ipad.png';
import Header from '../components/huntHeader';
import Layout from '../components/Layout';




const Hint5 = () => (
  <Layout>
    <Header /> 

    <header className="masthead4">
    <br></br>
    <br></br>
      <div className="container d-flex h-100 align-items-center">
        <div className="text-white-50 mx-auto text-center">
          <h1 className="mx-auto my-0 text-uppercase">Hint #5</h1>
          <br></br>
          <br></br>
          <h2 className="tracking-in-expand mx-auto mt-2 mb-5">
            {/* {config.subHeading} */}
            A quiet couple
            <br></br>
            holds the next clue.
            <br></br>
            <br></br>
            You've seen them before,
            <br></br>
            it's no one new.
            <br></br>
            <br></br>
            A surfer girl,
            <br></br>
            who is tan not pale.
            <br></br>
            <br></br>
            On her board
            <br></br>
            you will find your mail.
          <br></br>
          </h2>
          <br></br>
          <br></br>
          <h3 className="roll-in-bottom mx-auto">
          Distance: 1.1 miles N. East</h3>
          <br></br>
          <br></br>
          <a href="/clue5" className="btn btn-primary">
              Back to Clue
            </a>
            <br></br>
            <br></br>
            <br></br>
          {/* <Scroll type="id" element="about">  
            <a href="#about" className="btn btn-primary">
              Hint
            </a>
          </Scroll> */}
        </div>
      </div>
    </header>

    {/* <Clues />  

    <SocialLinks />
    <Footer /> */}
  </Layout>
);

export default Hint5;
